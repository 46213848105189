import { $themeConfig } from '@themeConfig'

export default {

  // api base url
  baseUrl: $themeConfig.app.apiBaseUrl,

  // api endpoints
  endpoints: {
    login: '/login',
    register: '/register',
    refresh: '/refresh-token', // TODO
    logout: '/logout', // TODO
    company: '/company', // TO DELETE

    campaigns: '/campaigns',
    designImage: '/designimage',
    myCompany: '/my/company',
    users: '/users',
    root: '/root',
  },

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
