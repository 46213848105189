import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {
    designLocations: null, // different sides of the carrier design
    qrPlacements: null, // placements for qr codes
  },
  getters: {
    getDesignLocations(state) {
      return state.designLocations
    },
    getQrPlacements(state) {
      return state.qrPlacements
    },
  },
  mutations: {
    SET_DESIGN_LOCATIONS(state, value) {
      state.designLocations = value
    },
    SET_QR_PLACEMENTS(state, value) {
      state.qrPlacements = value
    },
  },
  actions: {
    fetchDesignLocations({ commit }) {
      useJwt.getDesignImageLocations()
        .then(result => commit('SET_DESIGN_LOCATIONS', result.data.data))
    },
    fetchQrPlacements({ commit }) {
      useJwt.getQrCodePlacements()
        .then(result => commit('SET_QR_PLACEMENTS', result.data.data))
    },
  },
}
