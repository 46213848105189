export default [
  {
    path: '/',
    name: 'dashboard',
    component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
    meta: {
      resource: 'Dashboard',
      action: 'view',
    },
  },
]
