export default [

  /* MY COMPANY ROUTES */

  { // read
    path: '/company',
    name: 'view-mycompany',
    component: () => import('@/views/company/ViewCompany.vue'),
    meta: {
      resource: 'Company',
      action: 'read',
    },
  },
  { // update
    path: '/company/edit',
    name: 'edit-mycompany',
    component: () => import('@/views/company/EditCompany.vue'),
    meta: {
      resource: 'Company',
      action: 'update',
    },
  },
  { // add-user
    path: '/company/add-user',
    name: 'add-user',
    component: () => import('@/views/company/AddUser.vue'),
    meta: {
      resource: 'Company',
      action: 'create',
    },
  },

  /* COMPANY AS ROOT USER */

  { // create
    path: '/company/create',
    name: 'create-company',
    component: () => import('@/views/company/AddCompanyAsRoot.vue'),
    meta: {
      resource: 'role-admin',
      action: 'create',
    },
  },

  /* COMPANY ROUTES -- DEPRECATED -- TO REMOVE */

  { // browse
    path: '/companies',
    name: 'browse-companies',
    component: () => import('@/views/company/Browse.vue'),
    meta: {
      // current user must AT LEAST have the ability to browse companies or have the
      // ability to manage all resource types in order to access this route.
      resource: 'Company',
      action: 'browse',
    },
  },
  { // create
    path: '/landing/companies/create',
    name: 'landing-create-company',
    component: () => import('@/views/company/AddCompanyLanding.vue'),
    meta: {
      layout: 'full',
      resource: 'Company',
      action: 'create',
    },
  },
]
